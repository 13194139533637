@font-face {
    font-family: "DM Sans";
    font-weight: 700;
    src: url("../fonts/DMSans-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "DM Sans";
    font-weight: 700;
    font-style: italic;
    src: url("../fonts/DMSans-BoldItalic.ttf") format("truetype");
}

@font-face {
    font-family: "DM Sans";
    font-weight: 400;
    font-style: italic;
    src: url("../fonts/DMSans-Italic.ttf") format("truetype");
}

@font-face {
    font-family: "DM Sans";
    font-weight: 500;
    src: url("../fonts/DMSans-Medium.ttf") format("truetype");
}

@font-face {
    font-family: "DM Sans";
    font-weight: 500;
    font-style: italic;
    src: url("../fonts/DMSans-MediumItalic.ttf") format("truetype");
}

@font-face {
    font-family: "DM Sans";
    font-weight: 400;
    src: url("../fonts/DMSans-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Mabry Mono Pro";
    font-weight: 700;
    src: url("../fonts/MabryMono-Bold-Pro.ttf") format("truetype");
}

@font-face {
    font-family: "Mabry Mono Pro";
    font-weight: 400;
    src: url("../fonts/MabryMono-Regular-Pro.ttf") format("truetype");
}